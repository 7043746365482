import React from 'react';
import {Card, CardBody, CardHeader} from "reactstrap";

export default function NotFound({text = "Sorry, page not found :("}) {
    return <Card className="m-auto w-75">
        <CardHeader className="card-header-tab">
            <div className="card-header-title">
                <i className="header-icon lnr-magnifier icon-gradient bg-warm-flame"> </i> Not found
            </div>
        </CardHeader>
        <CardBody>{text}</CardBody>
    </Card>
}