import React from 'react';
import PageTitle from "../App/components/PageTitle";
import {Card, CardTitle, Col, Container, ListGroup, ListGroupItem, Row} from "reactstrap";
import {Link} from "react-router-dom";
import Loaded from "../App/components/Loaded";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBookOpen} from "@fortawesome/free-solid-svg-icons";

export default function OrgSelector({orgs, adminURL, user}) {
    return <>
        {
            Object.values(orgs).length === 0 &&
            <>
                <Container fluid className={"text-center"}>
                    <h3 className="mt-3 mb-3">
                        Glad to have you on board, <b>{user.getEmail()}</b>!
                    </h3>
                    <p className="mb-3">To start with Service360 you can</p>
                    <Container>
                        <Row className="justify-content-md-center">
                            <Col md={3}>
                                <Card className="mb-3 card-border shadow-sm my-auto widget-chart widget-chart-hover"
                                      body inverse
                                      color="info">
                                    <CardTitle className="text-white">Create new organisation</CardTitle>
                                    <a className="stretched-link card-link" style={{color: "inherit"}} href={adminURL}>Start
                                        here to create your own library</a>
                                </Card>
                            </Col>
                            <Col md={1} className="text-center my-auto">
                                <span className="align-middle text-muted">&mdash; or &mdash; </span>
                            </Col>
                            <Col md={3}>
                                <Card className="mb-3 card-border shadow-sm my-auto widget-chart widget-chart-hover"
                                      body inverse
                                      color="success">
                                    <CardTitle className="text-white">Explore the demo</CardTitle>
                                    <Link to={"/demo"} className="stretched-link card-link" style={{color: "inherit"}}>
                                        Check out Service360 functionality
                                    </Link>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Col md={7}>
                                <hr/>
                                <Card className="mb-3 shadow-none" body outline color="secondary">
                                    <CardTitle>Request access to an existing organisation</CardTitle>
                                    Contact organisation administrator via email or chat and ask for an access
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </>
        }
        {
            Object.values(orgs).length !== 0 &&
            <>
                <PageTitle
                    heading="Choose organisation"
                    icon="pe-7s-car icon-gradient bg-mean-fruit"
                />
                <Container fluid>
                    <Row className="justify-content-md-center">
                        {
                            Object.values(orgs).filter(x => x.getId() !== "demo").map((org, i) => {
                                return <>
                                    <Col md={4} lg={3} sm={6}>
                                        <Loaded item={org} message={"loading org data..."} key={org.getId()}>
                                            <OrgCard org={org}/>
                                        </Loaded>
                                    </Col>
                                </>
                            })
                        }
                    </Row>
                    <Row>
                        <Col>
                            <hr/>
                            <Container>
                                <Row className="justify-content-md-center">
                                    <Col md={3}>
                                        <Card
                                            className="mb-3 card-border shadow-sm my-auto widget-chart widget-chart-hover"
                                            body inverse
                                            color="info">
                                            <CardTitle className="text-white">Create new organisation</CardTitle>
                                            <a className="stretched-link card-link" style={{color: "inherit"}}
                                               href={adminURL}>Start here to create your own library</a>
                                        </Card>
                                    </Col>
                                    <Col md={1} className="text-center my-auto">
                                        <span className="align-middle text-muted">&mdash; or &mdash; </span>
                                    </Col>
                                    <Col md={3}>
                                        <Card
                                            className="mb-3 card-border shadow-sm my-auto widget-chart widget-chart-hover"
                                            body inverse
                                            color="success">
                                            <CardTitle className="text-white">Explore the demo</CardTitle>
                                            <Link to={"/demo"} className="stretched-link card-link"
                                                  style={{color: "inherit"}}>
                                                Check out Service360 functionality
                                            </Link>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </>
        }

    </>;
}

function OrgCard({org}) {
    return <Card className="main-card mb-3">
        <ListGroup flush>
            <ListGroupItem className="center-elem">
                <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                        <div className="widget-content-left mr-3">
                            <div className="icon-wrapper border-light rounded m-0">
                                <div className="icon-wrapper-bg bg-light"/>
                                <i className="lnr-cog icon-gradient bg-happy-itmeo"/>
                            </div>
                        </div>
                        <div className="widget-content-left ">
                            <div className="widget-heading">
                                <h5 className="menu-header-title text-capitalize">
                                    <Link to={"/" + org.getId()}>{org.getName()}</Link>
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </ListGroupItem>
            {
                org.getNonFilterLibraries().length !== 0 &&
                org.getNonFilterLibraries().map(lib => {
                    return <ListGroupItem>
                        <Row>
                            <Col>
                                <div className="ml-3">
                                    <h7><Link className="text-uppercase" to={lib.link()}><FontAwesomeIcon
                                        icon={faBookOpen}/> {lib.name}</Link></h7>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12" md="4" lg={4}>
                                <div className="widget-chart p-0">
                                    <div className="widget-chart-content">
                                        <div
                                            className="widget-numbers text-success fsize-3">
                                            {lib.details.servicesCount}
                                        </div>
                                        <div className="widget-subheading pt-1">
                                            Services
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm="12" md="4" lg={4}>
                                <div className="widget-chart p-0">
                                    <div className="widget-chart-content">
                                        <div
                                            className="widget-numbers text-warning fsize-3">
                                            {lib.details.contributorsCount}
                                        </div>
                                        <div className="widget-subheading pt-1">
                                            Contributors
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm="12" md="4" lg={4}>
                                <div className="widget-chart p-0">
                                    <div className="widget-chart-content">
                                        <div
                                            className="widget-numbers text-danger fsize-3">
                                            {lib.details.releasesLast7Days || 0}
                                        </div>
                                        <div className="widget-subheading pt-1">
                                            Releases lately
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </ListGroupItem>
                })
            }
        </ListGroup>
    </Card>
}