import {Route} from "react-router-dom";
import React from "react";
import {NavButton} from "../../components/ServiceRegistry/components/SectionSelector";
import {Context} from "../../hooks/usePlugins";

const descriptor = {
    type: "OwnerIframeTab",
    //Executed during org loading
    registerOrgEventHandler: (org, registerEventHandler) => {
    },

    //executed during library loading
    registerLibEventHandler: (config, registerEventHandler) => {
        if (!config.Tabs) {
            return
        }

        config.Tabs.forEach(tabConfig => {
            registerEventHandler("OwnerRoute:render", Iframe(tabConfig.route, tabConfig.source), Context(tabConfig.owner))
            registerEventHandler("OwnerNavigation:render", TabNavi(tabConfig.name, tabConfig.route), Context(tabConfig.owner))
        })
    }
}

export default descriptor;

function Iframe(route, source) {
    return () => {
        return <Route path={"/:orgSlug/:libraryId/team/owner/:name/" + route} render={() => {
            return <div>
                <iframe src={source} title={source} style={{
                    width: '80vw',
                    height: '80vh',
                    border: 0
                }}/>
            </div>
        }}/>
    }
}

function TabNavi(name, route) {
    return ({owner}) => {
        return <NavButton to={owner.linkToOwnerProfile() + "/" + route}>{name}</NavButton>
    }
}