import {score} from "../utils/contributors";
import {daysSince} from "../utils/date";
import ServiceDocs from "./serviceDocs";
import {getServiceComplexity} from "../utils/complexity";

export const STATUS_DEPRECATED = "deprecated";
export const STATUS_RETIRED = "retired";
export const STATUS_IN_DEVELOPMENT = "in development";
export const STATUS_PRODUCTION = "production";

export const CONTEXT_MAINTENANCE_ONLY = "MaintenanceOnly";

export const TYPE_WEB_SERVICE = "web service";
export const TYPE_DESKTOP_APP = "desktop app";
export const TYPE_EMBEDDED_APP = "embedded app";
export const TYPE_LIBRARY = "library";
export const TYPE_SUPPORT = "support";
export const TYPE_MOBILE_APP = "mobile app";
export const TYPE_EXTERNAL = "external";

export default class Service {
    docs;

    constructor(id, service, lib, loader) {
        this.id = id;
        this.srv = service;
        this.lib = lib;
        this.loader = loader;

        this._firstContribution = undefined;
        this._lastContribution = undefined;
        this._releasesLoaded = false;

        this.docs = new ServiceDocs(this, this.srv.Docs);
    }

    getId() {
        return (this.srv.Repository + "/" + this.srv.RepositoryFolder)
            .replace(new RegExp("[/]+$"), "")
            .split("/")
            .join("_");
    }

    getApiId() {
        return (this.srv.API.Repository + "/" + this.srv.API.RepositoryFolder)
            .replace(new RegExp("[/]+$"), "")
            .split("/").join("_")
    }

    getRepo() {
        return this.srv.Repository;
    }

    getRepoLink() {
        return this.srv.Repository + "/" + this.srv.RepositoryFolder
    }

    getSize() {
        return this.getPassport("__Size");
    }

    getName() {
        if (this.srv === undefined) {
            return this.id;
        }
        if (this.srv.Passport && this.srv.Passport.Passport.Name) {
            return this.srv.Passport.Passport.Name;
        }
        if (this.srv.PlantUML != null) {
            return this.srv.PlantUML.Name;
        }
        return this.id;
    }

    getContexts() {
        if (this.srv.Passport && this.srv.Passport.Passport.Context) {
            return this.srv.Passport.Passport.Context
        }
        if (this.srv.Passport && this.srv.Passport.Passport.Contexts) {
            return this.srv.Passport.Passport.Contexts
        }
    }

    isInMaintenanceOnlyMode() {
        return this.hasContext(CONTEXT_MAINTENANCE_ONLY)
    }

    hasContext(context) {
        return this.getContexts() && this.getContexts().indexOf(context) !== -1
    }

    getNavSummary(full) {
        const summary = [];
        if (this.srv.Passport && this.srv.Passport.Passport.Owner) {
            summary.push(this.srv.Passport.Passport.Owner + "");
        }
        if (full && this.srv.Passport && this.srv.Passport.Passport.Tier) {
            summary.push(this.srv.Passport.Passport.Tier + "");
        }
        if (full && this.srv.Passport && this.srv.Passport.Passport.Status) {
            summary.push(this.srv.Passport.Passport.Status + "");
        }
        return summary;
    }

    lastUpdatedAt() {
        return this.srv.ScrappedAt;
    }

    getDeprecated() {
        if (this.srv.Passport && this.srv.Passport.Passport && this.srv.Passport.Passport.Deprecated) {
            return this.srv.Passport.Passport.Deprecated;
        }
    }

    getDescription() {
        if (this.srv.Passport && this.srv.Passport.Passport && this.srv.Passport.Passport.Description) {
            return this.srv.Passport.Passport.Description;
        }
    }

    getOwner() {
        if (this.srv.Passport && this.srv.Passport.Passport && this.srv.Passport.Passport.Owner) {
            return this.srv.Passport.Passport.Owner;
        }
    }

    async getRenderedPlantUMLContent() {
        if (!this.renderedPuml) {
            this.renderedPuml = await this.loader
                .get(
                    this.lib.getDataURI() + "/plantuml/" + this.getId() + ".svg",
                    {
                        headers: {
                            "Accept": "image/svg+xml"
                        }
                    }
                );
        }
        return this.renderedPuml;
    }

    areReleasesLoaded() {
        return this._releasesLoaded;
    }

    async loadReleases() {
        if (!this.areReleasesLoaded()) {
            const response = await this.loader
                .get(
                    this.lib.getDataURI() + "/releases/" + this.getId()
                );
            this.srv.Releases = response.data;
            this._releasesLoaded = true
        }
        return this.areReleasesLoaded();
    }

    getTech() {
        const techSection = this.getPassport("Tech");
        if (!techSection || typeof techSection.forEach === "undefined") {
            return undefined;
        }
        const tech = {};

        techSection.forEach(t => {
            const techWithDescription = t.split(":");
            if (techWithDescription.length < 2) {
                tech[t.trim()] = "";
            } else {
                const name = techWithDescription.shift().trim();
                tech[name] = techWithDescription.join(":").trim();
            }
        });

        return tech;
    }

    getApiURL() {
        return (this.lib.getDataURI() + "/apis/" + this.getApiId()).substr(1);
    }

    linkToRegistry() {
        return this.lib.link() + "/registry/" + this.id;
    }

    linkToRegistryOverview() {
        return this.lib.link() + "/registry/" + this.id + "/overview";
    }

    linkToRegistryReleases() {
        return this.lib.link() + "/registry/" + this.id + "/releases";
    }

    linkToRegistryHistory() {
        return this.lib.link() + "/registry/" + this.id + "/history";
    }

    linkToRegistryContributors() {
        return this.lib.link() + "/registry/" + this.id + "/contributors";
    }

    linkToRegistryDependencies() {
        return this.lib.link() + "/registry/" + this.id + "/deps";
    }

    linkToRegistryDocs() {
        return this.lib.link() + "/registry/" + this.id + "/docs";
    }

    linkToRegistryApi() {
        return this.lib.link() + "/registry/" + this.id + "/api";
    }

    hasApi() {
        return this.srv.API;
    }

    getDependencies() {
        if (this.hasDependencies() && this.srv.PlantUML.Nodes) {
            return Object.keys(this.srv.PlantUML.Nodes)
                .filter(name => name !== this.getName())
        }
        return [];
    }

    getHardDependencies() {
        const deps = [];
        if (this.hasDependencies() && this.srv.PlantUML.Connections) {
            for (const connection of this.srv.PlantUML.Connections) {
                if (connection.From === this.getName() && connection.Arrow.indexOf(".") === -1) {
                    deps.push(connection.To);
                }
            }
        }
        return deps;
    }

    getDaysSinceLastAudit() {
        if (!this.getLastAuditDate()) {
            return 0;
        }

        return daysSince(this.getLastAuditDate());
    }

    getDaysSinceLastRelease() {
        if (!this.hasReleases()) {
            return 0;
        }

        const latestReleaseDate = this.srv.Releases.Releases[0].Date;
        return daysSince(latestReleaseDate);
    }

    getActivityScore() {
        if (this.activityScore === undefined) {
            this.activityScore = 0;
            if (this.hasContributors()) {
                for (const c of this.srv.Contributors.Contributors) {
                    this.activityScore += score(c, "LastWeeks");
                }
            }
        }

        return this.activityScore;
    }

    getSMEs() {
        return this.getContributors().filter(x => x.isSME);
    }

    // returns contributors sorted by SME
    getContributors(top) {
        if (this.__sme === undefined) {
            if (!this.hasContributors()) {
                return [];
            }
            this.__sme = this.srv.Contributors.Contributors;
            for (const c of this.__sme) {
                c._SMEDaysSince = daysSince(c.LastContribution);
                c._SMEScore = score(c);
            }
            this.__sme = this.__sme.sort((a, b) => {
                return a._SMEScore < b._SMEScore ? 1 : -1;
            });

            const srvLOC = this.getTotalContributedLOC();

            let SMEsFound = 0;
            for (const c of this.__sme) {
                const totalContributedLOC = parseInt(c["Total"].NLOC);
                const ownershipPercentage = (totalContributedLOC / srvLOC * 100).toFixed(1);

                if (ownershipPercentage > 1 || totalContributedLOC > 500) {
                    c.isSME = true;
                    if (++SMEsFound >= 2) {
                        break;
                    }
                }
            }
        }

        if (top === undefined) {
            top = this.__sme.length;
        }

        if (top > this.__sme.length) {
            top = this.__sme.length
        }

        return this.__sme.slice(0, top)
    }

    getFirstContributionDate() {
        if (this._firstContribution === undefined) {
            this.getContributors().forEach(x => {
                if (x.FirstContribution === 0) {
                    return
                }
                if (this._firstContribution === undefined || x.FirstContribution < this._firstContribution) {
                    this._firstContribution = x.FirstContribution;
                }
            })
        }

        return this._firstContribution;
    }

    getLastAuditDate() {
        if (this._lastAudit === undefined) {
            this._lastAudit = this.getFirstContributionDate();

            const auditDocs = this.getDocs().getAuditDocs();

            if (auditDocs) {
                const filenameRegexp = /(\d{4})-(\d{2})-(\d{2})\.md/i;

                const properAuditDocs = Object.keys(auditDocs.Docs)
                    .filter(x => {
                        return filenameRegexp.test(x);
                    })
                    .map(x => {
                        const matches = filenameRegexp.exec(x);

                        return new Date(parseInt(matches[1]), parseInt(matches[2]) - 1, parseInt(matches[3]));
                    })
                    .sort((a, b) => {
                        return a > b ? -1 : 1;
                    });

                if (properAuditDocs.length > 0) {
                    this._lastAudit = properAuditDocs[0].getTime() / 1000
                }
            }
        }

        return this._lastAudit;
    }

    getTotalContributedLOC() {
        if (!this.hasContributors()) {
            return 0;
        }

        if (!this._totalContributedLOC) {
            this._totalContributedLOC = this.srv.Contributors.Contributors.reduce((acc, contrubutor) => {
                return acc + parseInt(contrubutor.Total.NLOC)
            }, 0)
        }

        return this._totalContributedLOC;
    }

    getCodebaseSize() {
        if (!this.hasContributors()) {
            return 0;
        }

        if (!this._codebaseSize) {
            this._codebaseSize = this.srv.Contributors.Contributors.reduce((acc, contrubutor) => {
                return acc + parseInt(contrubutor.Total.Additions) - parseInt(contrubutor.Total.Deletions)
            }, 0);

            if (this._codebaseSize < 0) {
                this._codebaseSize = 0;
            }
        }

        return this._codebaseSize;
    }

    getComplexity() {
        return getServiceComplexity(this.lib, this)
    }

    getLastContributionDate(asDate = false) {
        if (this._lastContribution === undefined) {
            this.getContributors().forEach(x => {
                if (x.LastContribution === 0) {
                    return
                }
                if (this._lastContribution === undefined || x.LastContribution > this._lastContribution) {
                    this._lastContribution = x.LastContribution;
                }
            })
        }

        return asDate ? new Date(this._lastContribution * 1000) : this._lastContribution;
    }

    getDaysSinceLastContribution() {
        return daysSince(this.getLastContributionDate(true));
    }

    isActive() {
        if (this.isExternal() || this.isRetired()) {
            return false;
        }

        return this.getDaysSinceLastContribution() < 90;
    }

    isDeprecated() {
        return (this.srv.Passport && this.srv.Passport.Passport && this.srv.Passport.Passport.Deprecated) || (this.getStatus() === STATUS_DEPRECATED);
    }

    hasExplicitStatus() {
        return (this.srv.Passport && this.srv.Passport.Passport && this.srv.Passport.Passport.Status)
    }

    hasExplicitType() {
        return (this.srv.Passport && this.srv.Passport.Passport && this.srv.Passport.Passport.Type)
    }

    getType() {
        if (this.hasExplicitType()) {
            return this.srv.Passport.Passport.Type.toLowerCase();
        }

        return TYPE_WEB_SERVICE;
    }

    getStatus() {
        if (this.hasExplicitStatus()) {
            return this.srv.Passport.Passport.Status.toLowerCase();
        }

        return STATUS_PRODUCTION;
    }

    isRetired() {
        return this.getStatus() === STATUS_RETIRED;
    }

    isWebService() {
        return this.getType() === TYPE_WEB_SERVICE;
    }

    isSupport() {
        return this.getType() === TYPE_SUPPORT;
    }

    isExternal() {
        return this.getType() === TYPE_EXTERNAL;
    }

    isLibrary() {
        return this.getType() === TYPE_LIBRARY;
    }

    isMobileApp() {
        return this.getType() === TYPE_MOBILE_APP;
    }

    isEmbedded() {
        return this.getType() === TYPE_EMBEDDED_APP;
    }

    isDesktopApp() {
        return this.getType() === TYPE_DESKTOP_APP;
    }

    isAbandoned() {
        return this.getDaysSinceLastContribution() > 365 && !this.isRetired();
    }

    hasDependencies() {
        return this.srv.PlantUML && this.srv.PlantUML.Nodes;
    }

    hasReleases() {
        return this.srv.Releases && this.srv.Releases.Releases && this.srv.Releases.Releases.length > 0;
    }

    getReleases() {
        if (this.hasReleases()) {
            return this.srv.Releases.Releases;
        }
        return [];
    }

    hasContributors() {
        return this.srv.Contributors && this.srv.Contributors.Contributors && this.srv.Contributors.Contributors.length > 0;
    }

    getDocs() {
        return this.docs;
    }

    hasDocs() {
        return (!this.getDocs().isEmpty()) || (this.getPassport("__NonStructured"));
    }

    hasPassport() {
        return (this.srv.Passport && this.srv.Passport.Passport);
    }

    getPassport(section) {
        if (!this.hasPassport()) {
            return undefined;
        }

        if (section) {
            return this.srv.Passport.Passport[section];
        }

        return this.srv.Passport.Passport;
    }
}