import React, {Fragment, useContext} from "react";
import {Button, UncontrolledTooltip} from "reactstrap";
import {Auth0Context} from "../../../../react-auth0-wrapper";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTools} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

export default function UserBox({org}) {
    const { isAuthenticated, logout, user } = useContext(Auth0Context);

    if (!isAuthenticated) {
        return null;
    }

    return <Fragment>
        <div className="header-btn-lg pr-0">
            <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                    <div className="widget-content-left">
                        {
                            org && <Link to={org.linkToUserProfile()}>
                                <img width={42} className="rounded-circle" src={user.picture} alt=""/>
                            </Link>
                        }
                        {
                            !org && <img width={42} className="rounded-circle" src={user.picture} alt=""/>
                        }
                    </div>
                    <div className="widget-content-left  ml-3 header-user-info">
                        <div className="widget-heading">
                            {
                                org && <Link to={org.linkToUserProfile()}>
                                    {user.nickname}
                                </Link>
                            }
                            {
                                !org && <>{user.nickname}</>
                            }
                        </div>
                        <div className="widget-subheading">
                            {user.name}
                        </div>
                    </div>

                    {
                        org && org.data.IsAdmin &&
                        <div className="widget-content-right header-user-info ml-3">
                            <Button className="btn-shadow p-1" size="sm" color="info"
                                    id="toAdmin">
                                <a href={org.linkToAdmin()}>&nbsp;<FontAwesomeIcon icon={faTools} color="#ffffff" fontSize="25px" />&nbsp;</a>
                            </Button>
                            <UncontrolledTooltip placement="bottom" target={'toAdmin'}>
                                Go to admin interface
                            </UncontrolledTooltip>
                        </div>
                    }

                    <div className="widget-content-right header-user-info ml-3">
                        <Button className="btn-shadow p-1" size="sm" onClick={logout} color="secondary">
                            Logout
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
}