import Tech, {TechRadarStatusEntry} from "./tech";
import unique from "../utils/filter";

export default class TechRadar {

    hasDedicatedRadar = false;
    isLoaded = false;
    techRaw = {
        RepositoryLink: ""
    };

    techTypes = [];

    constructor(lib, techOverview, parentRadar) {
        this.lib = lib;
        this.hasDedicatedRadar = !!techOverview;
        this.overview = new TechRadarOverview(this, techOverview);
        this.parentRadar = parentRadar;

        if (!this.hasDedicatedRadar) {
            this.isLoaded = true;
        }

        this.appliedTech = getAppliedTechs(lib);
    }

    getAppliedTech() {
        return this.appliedTech;
    }

    isDedicatedRadar() {
        return this.hasDedicatedRadar;
    }

    getOverview() {
        return this.overview;
    }

    getTechNames() {
        const map = {...this.getAppliedTech()};

        if (this.isDedicatedRadar()) {
            for (let tech of this.getOverview().getTechs()) {
                map[tech.getName()] = true;
                if (tech.getAlias()) {
                    for (let a of tech.getAlias()) {
                        map[a] = true;
                    }
                }
            }
        }

        return Object.keys(map);
    }

    getAlienTechs() {
        const orphanTechs = [];

        if (this.isDedicatedRadar()) {
            for (let tech of Object.values(this.getAppliedTech())) {
                let found = undefined;
                for (let techType of this.getTypes()) {
                    found = techType.getTechByNameOrAlias(tech.getName());
                    if (found !== undefined) {
                        break;
                    }
                }

                if (found === undefined) {
                    orphanTechs.push(tech)
                }
            }
        }

        return orphanTechs;
    }

    getTech(name) {
        if (this.isDedicatedRadar()) {
            for (let techType of this.getTypes()) {
                const found = techType.getTechByNameOrAlias(name);
                if (found) {
                    return found;
                }
            }

            const found = this.getOverview().getTechByNameOrAlias(name);
            if (found) {
                return found;
            }
        }

        return Object.values(this.appliedTech).find(x => x.is(name))
    }

    getTags() {
        return this.getTypes()
            .flatMap(x => x.getTechs())
            .flatMap(x => x.getTags())
            .map(x => x.toLowerCase())
            .filter(unique)
            .sort((a, b) => a > b ? 1 : -1)
    }

    async ready() {
        if (this.parentRadar !== undefined) {
            if (!this.parentRadar.isLoaded) {
                await this.parentRadar.ready();
            }

            this.techRaw = this.parentRadar.techRaw;
        } else {
            const result = await this.lib.loader.get(this.lib.getDataURI() + "/techradar/");

            this.techRaw = result.data;
        }

        this.techTypes = [];

        if (this.techRaw.TechnologyTypes) {
            this.techTypes = this.techRaw.TechnologyTypes.map(x => {
                if (!x.Technologies) {
                    return undefined
                }
                const techType = new TechnologyType(this, x.Name, x.Technologies);

                for (let appliedTechKey of Object.keys(this.appliedTech)) {
                    const appliedTech = this.appliedTech[appliedTechKey];
                    const realTech = techType.getTechByNameOrAlias(appliedTech.getName());

                    //Merge applied tech with real tech radar to get rid of aliased tech
                    if (realTech) {
                        for (let application of appliedTech.getApplications()) {
                            realTech.addApplication(application.getService(), application.getApplication())
                        }
                        if (this.appliedTech[realTech.getName()] !== undefined) {
                            for (let application of this.appliedTech[realTech.getName()].getApplications()) {
                                realTech.addApplication(application.getService(), application.getApplication())
                            }
                        }

                        delete this.appliedTech[appliedTechKey];
                        this.appliedTech[realTech.getName()] = realTech;
                    }
                }

                return techType
            }).filter(x => x);
        }

        this.isLoaded = true;
    }

    getTypes() {
        return this.techTypes;
    }

    getRepositoryLink() {
        return this.techRaw.RepositoryLink;
    }
}

class TechnologyType {
    tech = [];

    constructor(radar, name, techData) {
        this.radar = radar;
        this.name = name;
        techData.forEach(x => {
            this.tech.push(
                new Tech(
                    radar.lib,
                    x.Name,
                    new TechRadarStatusEntry(x.Status, x.Date, x.Description, x.Alias, x.Tags),
                    x.History ? x.History.map(x => new TechRadarStatusEntry(x.Status, x.Date, x.Description, x.Alias, x.Tags)) : [],
                    x
                )
            );
        })
    }

    getName() {
        return this.name;
    }

    getTechs() {
        return this.tech;
    }

    getTechByNameOrAlias(name) {
        return this.getTechs().find(x => x.is(name))
    }
}

function getAppliedTechs(lib) {
    const appliedTechs = {};

    lib.getServices()
        // .filter(x => !x.isRetired())
        .forEach(srv => {
            const tech = srv.getTech();
            if (!tech) {
                return;
            }

            for (const name in tech) {
                if (!appliedTechs[name]) {
                    appliedTechs[name] = new Tech(lib, name);
                }

                appliedTechs[name].addApplication(srv, tech[name]);
            }
        });

    return appliedTechs;
}

class TechRadarOverview {
    techs = [];

    constructor(radar, techOverview) {
        this.radar = radar;
        this.techOverview = techOverview;
        if (techOverview) {
            this.techs = techOverview.Technologies.map(x => new Tech(radar.lib, x.Name, new TechRadarStatusEntry(
                x.Status,
                undefined,
                undefined,
                x.Alias
            )))
        }
    }

    getTechs() {
        return this.techs
    }

    getTechByNameOrAlias(name) {
        return this.getTechs().find(x => x.is(name))
    }
}