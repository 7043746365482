import React, {lazy, Suspense, useEffect, useState} from 'react';
import ResizeDetector from 'react-resize-detector';
import SweetAlert from "sweetalert-react";
import axios from "axios";
import {Loader} from "react-loaders";
import BlockUi from "react-block-ui";
import {Redirect, Route, Switch} from 'react-router-dom'
import {useAuth0} from "../../react-auth0-wrapper";
import AppContext from "./AppContext";
import User from "../../domain/user";
import {withTracker} from "../../hooks/withTracker";
import OrganisationOverview from "../OrganisationOverview";
import TopNavBar from "./components/TopNavBar";
import AppMain from "./components/AppMain";
import NotFound from "./components/NotFound";
import MainIntro from "./components/Intro/MainIntro";
import OrgSelector from "../OrgSelector";
import Loaded from "./components/Loaded";
import cx from 'classnames';
import useTheme from "../../hooks/useTheme";
import usePlugins, {Context} from "../../hooks/usePlugins";
import Profile from "../Profile";

const BigPicture = lazy(() => import('../BigPicture'));
const ServiceRegistry = lazy(() => import('../ServiceRegistry'));
const Pulse = lazy(() => import('../Pulse'));
const Team = lazy(() => import('../Team'));
const Tech = lazy(() => import('../Tech'));
const RiskRadarPerspective = lazy(() => import('../RiskRadar'));

const MSG_LOGGING_IN = "Securely logging you in";
const MSG_LOADING_LIBRARY = "Loading your organisation";

function LoadingError({error}) {
    const {logout} = useAuth0();

    let title = "Error";
    let text = error.message;
    let alertType = "error";

    switch (error.response.status) {
        case 403:
            title = "We don't know you";
            alertType = "info";
            text = "Looks like you need to register (or request access) before you will be able to access Service360";
            break;
        case 404:
            title = "Organisation is not found";
            alertType = "error";
            text = "Oops! Looks like you have just created an organisation and your library is not yet ready. Wait a bit or check admin area for possible errors";
            break;
        default:
            break;
    }

    return <SweetAlert
        title={title}
        confirmButtonColor=""
        show={true}
        text={text}
        confirmButtonText="Logout"
        onConfirm={() => logout({
            returnTo: document.location.origin
        })}
        type={alertType}/>
}

function SuspenseWithLoader({children, fallback}) {
    return <Suspense fallback={
        <BlockUi tag="div" blocking={true}
                 keepInView={true}
                 message={fallback}
                 loader={<Loader active type="ball-pulse"/>}
        >
            <div className="app-container app-theme-white fixed-header"/>
        </BlockUi>
    }>
        {children}
    </Suspense>
}

function App({adminURL, api, auth0Domain, auth0ClientID}) {
    const {loading, isAuthenticated, loginWithRedirect, getTokenSilently, user: auth0user, client: auth0Client} = useAuth0();
    const [user, setUser] = useState();
    const [token, setToken] = useState();
    const [loadingError, setLoadingError] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("Loading your Service360");
    const {mobileSidebarOpen} = useTheme();
    const plugins = usePlugins();

    window.auth0Client = auth0Client;

    useEffect(() => {
        window.Userback = window.Userback || {};
        window.Userback.access_token = '8196|13864|bFAtQwKadKA70npXvy6yyVFvezDJ5ZTzpkY84Zkt8B8M20KNKC';
        (function (id) {
            const s = document.createElement('script');
            s.async = 1;
            s.src = 'https://static.userback.io/widget/v1.js';
            const parent_node = document.head || document.body;
            parent_node.appendChild(s);
        })('userback-sdk');
    }, []);

    const containerClass = (library, additional = "") => {
        return cx("app-container app-theme-white fixed-header " + additional,
            {'closed-sidebar': mobileSidebarOpen},
            {'closed-sidebar-mobile': !mobileSidebarOpen},
            {'sidebar-mobile-open': mobileSidebarOpen},
            {'with-filter-navi': library && library.isFiltered()}
        )
    };

    useEffect(() => {
        const loadUser = async () => {
            setLoadingMessage(MSG_LOGGING_IN);
            try {
                const token = await getTokenSilently();

                setLoadingMessage(MSG_LOADING_LIBRARY);

                const loader = axios.create({
                    baseURL: api,
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                });

                const u = new User(adminURL, loader, auth0user);

                await u.ready();
                setToken(token);
                setUser(u);
            } catch (e) {
                console.log(e);
                setLoadingError(e);
            }
        };

        if (!loading) {
            if (isAuthenticated) {
                loadUser();
            } else {
                loginWithRedirect();
            }
        }
    }, [loading, isAuthenticated]);

    window.u = user;

    return (<>
            {
                loadingError &&
                <LoadingError error={loadingError}/>
            }
            {
                !loadingError && (loading || !isAuthenticated || (isAuthenticated && !user)) &&
                <BlockUi tag="div" blocking={true}
                         keepInView={true}
                         message={loadingMessage}
                         loader={<Loader active type="ball-pulse"/>}
                >
                    <div className="app-container app-theme-white fixed-header"/>
                </BlockUi>
            }
            {
                !loadingError && !loading && isAuthenticated && user &&
                <ResizeDetector
                    handleWidth
                    render={(width) => (
                        <AppContext.Provider value={{
                            user: user,
                            token: token,
                            auth0ClientID: auth0ClientID,
                            auth0Domain: auth0Domain,
                            adminURL: adminURL,
                            baseURL: api
                        }}>
                            <Switch>
                                <Route exact path="/" render={() => {
                                    return (
                                        <div className={containerClass()}>
                                            <TopNavBar noSidebarToggle noTopNaviToggle/>
                                            <AppMain>
                                                <OrgSelector user={user} orgs={user.getOrgs()} adminURL={adminURL}/>
                                            </AppMain>
                                        </div>
                                    );
                                }}/>

                                <Route path="/:orgSlug" render={(route) => {
                                    const organisation = user.getOrg(route.match.params.orgSlug);

                                    if (!organisation) {
                                        return <div className={containerClass()}>
                                            <TopNavBar noSidebarToggle noTopNaviToggle/>
                                            <AppMain>
                                                <NotFound text={"Sorry, organisation is not found :("}/>
                                            </AppMain>
                                        </div>
                                    }

                                    window.org = organisation;
                                    document.title = "S360 - " + organisation.getName();

                                    return <Loaded item={organisation} key={organisation.getId()}
                                                   onSuccess={(org) => {
                                                       plugins.registerPlugins(org, org.getPlugins())
                                                   }}
                                                   message={"Loading organisation details"}>
                                        <Switch>
                                            <Route exact path="/:orgSlug" render={() => {
                                                return (
                                                    <div className={containerClass()}>
                                                        <MainIntro user={user}/>
                                                        <TopNavBar organisation={organisation} noSidebarToggle
                                                                   noTopNaviToggle/>
                                                        <AppMain>
                                                            <OrganisationOverview organisation={organisation}/>
                                                        </AppMain>
                                                    </div>
                                                );
                                            }}/>


                                            <Route path="/:orgSlug/profile" render={() => {
                                                return (
                                                    <div className={containerClass()}>
                                                        <TopNavBar noSidebarToggle
                                                                   organisation={organisation}/>
                                                        <AppMain>
                                                            <Profile org={organisation} />
                                                        </AppMain>
                                                    </div>
                                                );
                                            }}/>

                                            <Route path="/:orgSlug/:libraryId" render={(route) => {
                                                const library = organisation.getLibrary(route.match.params.libraryId);

                                                if (!library) {
                                                    return <div className={containerClass()}>
                                                        <TopNavBar organisation={organisation}/>
                                                        <AppMain>
                                                            <NotFound text={"Sorry, library is not found :("}/>
                                                        </AppMain>
                                                    </div>
                                                }

                                                window.library = library;

                                                document.title = "S360 - " + organisation.getName() + ": " + library.getName();

                                                const redirectFrom = library.link();
                                                const redirectTo = library.link() + "/registry";
                                                return (
                                                    <Loaded item={library} key={library.id} message={"Loading library"}
                                                            onSuccess={(loadedLib) => {
                                                                plugins.initLibraryPlugins(loadedLib, loadedLib.getPluginConfigs());
                                                            }}>
                                                        <Switch>
                                                            <Redirect exact from={redirectFrom} to={redirectTo}/>

                                                            <Route path="/:orgSlug/:libraryId/registry"
                                                                   render={(route) => {
                                                                       return (
                                                                           <div
                                                                               className={containerClass(library, "fixed-sidebar")}>
                                                                               <TopNavBar library={library}
                                                                                          organisation={organisation}/>
                                                                               <SuspenseWithLoader
                                                                                   fallback={"Loading service registry..."}>
                                                                                   <ServiceRegistry library={library}/>
                                                                               </SuspenseWithLoader>
                                                                           </div>
                                                                       );
                                                                   }}/>

                                                            <Route path="/:orgSlug/:libraryId/riskradar"
                                                                   render={(route) => {
                                                                       return (
                                                                           <div className={containerClass(library)}>
                                                                               <TopNavBar library={library}
                                                                                          noSidebarToggle
                                                                                          organisation={organisation}/>
                                                                               <SuspenseWithLoader
                                                                                   fallback={"Loading risk radar..."}>
                                                                                   <RiskRadarPerspective
                                                                                       library={library}/>
                                                                               </SuspenseWithLoader>
                                                                           </div>
                                                                       );
                                                                   }}/>

                                                            <Route path="/:orgSlug/:libraryId/pulse"
                                                                   render={(route) => {
                                                                       return (
                                                                           <div className={containerClass(library)}>
                                                                               <TopNavBar library={library}
                                                                                          noSidebarToggle
                                                                                          organisation={organisation}/>
                                                                               <SuspenseWithLoader
                                                                                   fallback={"Loading pulse..."}>
                                                                                   <Pulse library={library}/>
                                                                               </SuspenseWithLoader>
                                                                           </div>
                                                                       );
                                                                   }}/>
                                                            <Route path="/:orgSlug/:libraryId/bigpicture"
                                                                   render={(route) => {
                                                                       return (
                                                                           <div className={containerClass(library)}>
                                                                               <TopNavBar library={library}
                                                                                          noSidebarToggle
                                                                                          organisation={organisation}/>
                                                                               <SuspenseWithLoader
                                                                                   fallback={"Loading big picture..."}>
                                                                                   <BigPicture library={library}/>
                                                                               </SuspenseWithLoader>
                                                                           </div>
                                                                       );
                                                                   }}/>
                                                            <Route path="/:orgSlug/:libraryId/team" render={(route) => {
                                                                return (
                                                                    <div
                                                                        className={containerClass(library, "fixed-sidebar")}>
                                                                        <TopNavBar library={library}
                                                                                   organisation={organisation}/>
                                                                        <SuspenseWithLoader
                                                                            fallback={"Loading team..."}>
                                                                            <Team library={library}/>
                                                                        </SuspenseWithLoader>
                                                                    </div>
                                                                );
                                                            }}/>

                                                            <Route path="/:orgSlug/:libraryId/tech/:tech"
                                                                   render={(route) => {
                                                                       return (
                                                                           <div
                                                                               className={containerClass(library, "fixed-sidebar")}>
                                                                               <TopNavBar library={library}
                                                                                          noSidebarToggle
                                                                                          organisation={organisation}/>
                                                                               <SuspenseWithLoader
                                                                                   fallback={"Loading tech..."}>
                                                                                   <Tech library={library}/>
                                                                               </SuspenseWithLoader>
                                                                           </div>
                                                                       );
                                                                   }}/>
                                                            <Route path="/:orgSlug/:libraryId/tech" render={(route) => {
                                                                return (
                                                                    <div className={containerClass(library)}>
                                                                        <TopNavBar library={library}
                                                                                   noSidebarToggle
                                                                                   organisation={organisation}/>
                                                                        <SuspenseWithLoader
                                                                            fallback={"Loading tech..."}>
                                                                            <Tech library={library}/>
                                                                        </SuspenseWithLoader>
                                                                    </div>
                                                                );
                                                            }}/>
                                                            <Route render={(route) => {
                                                                return (
                                                                    <div className={containerClass(library)}>
                                                                        <TopNavBar library={library}
                                                                                   noSidebarToggle
                                                                                   organisation={organisation}/>
                                                                        <Switch>
                                                                            {
                                                                                plugins.render("LibraryRoute:render", {
                                                                                        org: organisation,
                                                                                        library: library,
                                                                                        route: route,
                                                                                    },
                                                                                    Context(library)
                                                                                )
                                                                            }
                                                                        </Switch>
                                                                    </div>
                                                                );
                                                            }}/>
                                                        </Switch>
                                                    </Loaded>
                                                )
                                            }}/>
                                        </Switch>
                                    </Loaded>
                                }}/>
                            </Switch>
                        </AppContext.Provider>
                    )}
                />
            }
        </>
    )
}


export default withTracker(App);