// This is a copy of node_modules/react-syntax-highlighter/dist/esm/languages/hljs/index.js
// with commented out exotic languages which use too much bandwidth
// Reduces module size > 60%

//export { default as oneC } from 'react-syntax-highlighter/dist/esm/languages/hljs/1c';
// export { default as abnf } from 'react-syntax-highlighter/dist/esm/languages/hljs/abnf';
export {default as accesslog} from 'react-syntax-highlighter/dist/esm/languages/hljs/accesslog';
export {default as actionscript} from 'react-syntax-highlighter/dist/esm/languages/hljs/actionscript';
// export { default as ada } from 'react-syntax-highlighter/dist/esm/languages/hljs/ada';
export {default as angelscript} from 'react-syntax-highlighter/dist/esm/languages/hljs/angelscript';
export {default as apache} from 'react-syntax-highlighter/dist/esm/languages/hljs/apache';
export {default as applescript} from 'react-syntax-highlighter/dist/esm/languages/hljs/applescript';
// export { default as arcade } from 'react-syntax-highlighter/dist/esm/languages/hljs/arcade';
export {default as arduino} from 'react-syntax-highlighter/dist/esm/languages/hljs/arduino';
// export { default as armasm } from 'react-syntax-highlighter/dist/esm/languages/hljs/armasm';
export {default as asciidoc} from 'react-syntax-highlighter/dist/esm/languages/hljs/asciidoc';
export {default as aspectj} from 'react-syntax-highlighter/dist/esm/languages/hljs/aspectj';
// export { default as autohotkey } from 'react-syntax-highlighter/dist/esm/languages/hljs/autohotkey';
// export { default as autoit } from 'react-syntax-highlighter/dist/esm/languages/hljs/autoit';
// export { default as avrasm } from 'react-syntax-highlighter/dist/esm/languages/hljs/avrasm';
export {default as awk} from 'react-syntax-highlighter/dist/esm/languages/hljs/awk';
// export { default as axapta } from 'react-syntax-highlighter/dist/esm/languages/hljs/axapta';
export {default as bash} from 'react-syntax-highlighter/dist/esm/languages/hljs/bash';
export {default as basic} from 'react-syntax-highlighter/dist/esm/languages/hljs/basic';
// export { default as bnf } from 'react-syntax-highlighter/dist/esm/languages/hljs/bnf';
//export { default as brainfuck } from 'react-syntax-highlighter/dist/esm/languages/hljs/brainfuck';
export {default as cal} from 'react-syntax-highlighter/dist/esm/languages/hljs/cal';
// export { default as capnproto } from 'react-syntax-highlighter/dist/esm/languages/hljs/capnproto';
// export { default as ceylon } from 'react-syntax-highlighter/dist/esm/languages/hljs/ceylon';
// export { default as clean } from 'react-syntax-highlighter/dist/esm/languages/hljs/clean';
export {default as clojureRepl} from 'react-syntax-highlighter/dist/esm/languages/hljs/clojure-repl';
export {default as clojure} from 'react-syntax-highlighter/dist/esm/languages/hljs/clojure';
export {default as cmake} from 'react-syntax-highlighter/dist/esm/languages/hljs/cmake';
export {default as coffeescript} from 'react-syntax-highlighter/dist/esm/languages/hljs/coffeescript';
// export { default as coq } from 'react-syntax-highlighter/dist/esm/languages/hljs/coq';
// export { default as cos } from 'react-syntax-highlighter/dist/esm/languages/hljs/cos';
export {default as cpp} from 'react-syntax-highlighter/dist/esm/languages/hljs/cpp';
// export { default as crmsh } from 'react-syntax-highlighter/dist/esm/languages/hljs/crmsh';
export {default as crystal} from 'react-syntax-highlighter/dist/esm/languages/hljs/crystal';
export {default as cs} from 'react-syntax-highlighter/dist/esm/languages/hljs/cs';
export {default as csp} from 'react-syntax-highlighter/dist/esm/languages/hljs/csp';
export {default as css} from 'react-syntax-highlighter/dist/esm/languages/hljs/css';
export {default as d} from 'react-syntax-highlighter/dist/esm/languages/hljs/d';
export {default as dart} from 'react-syntax-highlighter/dist/esm/languages/hljs/dart';
export {default as delphi} from 'react-syntax-highlighter/dist/esm/languages/hljs/delphi';
export {default as diff} from 'react-syntax-highlighter/dist/esm/languages/hljs/diff';
export {default as django} from 'react-syntax-highlighter/dist/esm/languages/hljs/django';
export {default as dns} from 'react-syntax-highlighter/dist/esm/languages/hljs/dns';
export {default as dockerfile} from 'react-syntax-highlighter/dist/esm/languages/hljs/dockerfile';
export {default as dos} from 'react-syntax-highlighter/dist/esm/languages/hljs/dos';
export {default as dsconfig} from 'react-syntax-highlighter/dist/esm/languages/hljs/dsconfig';
// export {default as dts} from 'react-syntax-highlighter/dist/esm/languages/hljs/dts';
export {default as dust} from 'react-syntax-highlighter/dist/esm/languages/hljs/dust';
// export {default as ebnf} from 'react-syntax-highlighter/dist/esm/languages/hljs/ebnf';
export {default as elixir} from 'react-syntax-highlighter/dist/esm/languages/hljs/elixir';
export {default as elm} from 'react-syntax-highlighter/dist/esm/languages/hljs/elm';
export {default as erb} from 'react-syntax-highlighter/dist/esm/languages/hljs/erb';
export {default as erlangRepl} from 'react-syntax-highlighter/dist/esm/languages/hljs/erlang-repl';
export {default as erlang} from 'react-syntax-highlighter/dist/esm/languages/hljs/erlang';
export {default as excel} from 'react-syntax-highlighter/dist/esm/languages/hljs/excel';
// export {default as fix} from 'react-syntax-highlighter/dist/esm/languages/hljs/fix';
// export {default as flix} from 'react-syntax-highlighter/dist/esm/languages/hljs/flix';
// export { default as fortran } from 'react-syntax-highlighter/dist/esm/languages/hljs/fortran';
export {default as fsharp} from 'react-syntax-highlighter/dist/esm/languages/hljs/fsharp';
// export { default as gams } from 'react-syntax-highlighter/dist/esm/languages/hljs/gams';
export {default as gauss} from 'react-syntax-highlighter/dist/esm/languages/hljs/gauss';
export {default as gcode} from 'react-syntax-highlighter/dist/esm/languages/hljs/gcode';
export {default as gherkin} from 'react-syntax-highlighter/dist/esm/languages/hljs/gherkin';
// export { default as glsl } from 'react-syntax-highlighter/dist/esm/languages/hljs/glsl';
//export { default as gml } from 'react-syntax-highlighter/dist/esm/languages/hljs/gml';
export {default as go} from 'react-syntax-highlighter/dist/esm/languages/hljs/go';
export {default as golo} from 'react-syntax-highlighter/dist/esm/languages/hljs/golo';
export {default as gradle} from 'react-syntax-highlighter/dist/esm/languages/hljs/gradle';
export {default as groovy} from 'react-syntax-highlighter/dist/esm/languages/hljs/groovy';
export {default as haml} from 'react-syntax-highlighter/dist/esm/languages/hljs/haml';
export {default as handlebars} from 'react-syntax-highlighter/dist/esm/languages/hljs/handlebars';
export {default as haskell} from 'react-syntax-highlighter/dist/esm/languages/hljs/haskell';
export {default as haxe} from 'react-syntax-highlighter/dist/esm/languages/hljs/haxe';
export {default as hsp} from 'react-syntax-highlighter/dist/esm/languages/hljs/hsp';
export {default as htmlbars} from 'react-syntax-highlighter/dist/esm/languages/hljs/htmlbars';
export {default as http} from 'react-syntax-highlighter/dist/esm/languages/hljs/http';
export {default as hy} from 'react-syntax-highlighter/dist/esm/languages/hljs/hy';
// export { default as inform7 } from 'react-syntax-highlighter/dist/esm/languages/hljs/inform7';
export {default as ini} from 'react-syntax-highlighter/dist/esm/languages/hljs/ini';
// export { default as irpf90 } from 'react-syntax-highlighter/dist/esm/languages/hljs/irpf90';
// export { default as isbl } from 'react-syntax-highlighter/dist/esm/languages/hljs/isbl';
export {default as java} from 'react-syntax-highlighter/dist/esm/languages/hljs/java';
export {default as javascript} from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
export {default as jbossCli} from 'react-syntax-highlighter/dist/esm/languages/hljs/jboss-cli';
export {default as json} from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
export {default as juliaRepl} from 'react-syntax-highlighter/dist/esm/languages/hljs/julia-repl';
export {default as julia} from 'react-syntax-highlighter/dist/esm/languages/hljs/julia';
export {default as kotlin} from 'react-syntax-highlighter/dist/esm/languages/hljs/kotlin';
// export { default as lasso } from 'react-syntax-highlighter/dist/esm/languages/hljs/lasso';
export {default as ldif} from 'react-syntax-highlighter/dist/esm/languages/hljs/ldif';
// export { default as leaf } from 'react-syntax-highlighter/dist/esm/languages/hljs/leaf';
export {default as less} from 'react-syntax-highlighter/dist/esm/languages/hljs/less';
export {default as lisp} from 'react-syntax-highlighter/dist/esm/languages/hljs/lisp';
// export { default as livecodeserver } from 'react-syntax-highlighter/dist/esm/languages/hljs/livecodeserver';
// export { default as livescript } from 'react-syntax-highlighter/dist/esm/languages/hljs/livescript';
export {default as llvm} from 'react-syntax-highlighter/dist/esm/languages/hljs/llvm';
export {default as lsl} from 'react-syntax-highlighter/dist/esm/languages/hljs/lsl';
export {default as lua} from 'react-syntax-highlighter/dist/esm/languages/hljs/lua';
export {default as makefile} from 'react-syntax-highlighter/dist/esm/languages/hljs/makefile';
export {default as markdown} from 'react-syntax-highlighter/dist/esm/languages/hljs/markdown';
// export { default as mathematica } from 'react-syntax-highlighter/dist/esm/languages/hljs/mathematica';
// export { default as matlab } from 'react-syntax-highlighter/dist/esm/languages/hljs/matlab';
// export { default as maxima } from 'react-syntax-highlighter/dist/esm/languages/hljs/maxima';
export {default as mel} from 'react-syntax-highlighter/dist/esm/languages/hljs/mel';
// export { default as mercury } from 'react-syntax-highlighter/dist/esm/languages/hljs/mercury';
// export { default as mipsasm } from 'react-syntax-highlighter/dist/esm/languages/hljs/mipsasm';
// export { default as mizar } from 'react-syntax-highlighter/dist/esm/languages/hljs/mizar';
export {default as mojolicious} from 'react-syntax-highlighter/dist/esm/languages/hljs/mojolicious';
export {default as monkey} from 'react-syntax-highlighter/dist/esm/languages/hljs/monkey';
export {default as moonscript} from 'react-syntax-highlighter/dist/esm/languages/hljs/moonscript';
// export { default as n1ql } from 'react-syntax-highlighter/dist/esm/languages/hljs/n1ql';
export {default as nginx} from 'react-syntax-highlighter/dist/esm/languages/hljs/nginx';
// export { default as nimrod } from 'react-syntax-highlighter/dist/esm/languages/hljs/nimrod';
export {default as nix} from 'react-syntax-highlighter/dist/esm/languages/hljs/nix';
export {default as nsis} from 'react-syntax-highlighter/dist/esm/languages/hljs/nsis';
export {default as objectivec} from 'react-syntax-highlighter/dist/esm/languages/hljs/objectivec';
export {default as ocaml} from 'react-syntax-highlighter/dist/esm/languages/hljs/ocaml';
// export { default as openscad } from 'react-syntax-highlighter/dist/esm/languages/hljs/openscad';
// export { default as oxygene } from 'react-syntax-highlighter/dist/esm/languages/hljs/oxygene';
export {default as parser3} from 'react-syntax-highlighter/dist/esm/languages/hljs/parser3';
export {default as perl} from 'react-syntax-highlighter/dist/esm/languages/hljs/perl';
export {default as pf} from 'react-syntax-highlighter/dist/esm/languages/hljs/pf';
export {default as pgsql} from 'react-syntax-highlighter/dist/esm/languages/hljs/pgsql';
export {default as php} from 'react-syntax-highlighter/dist/esm/languages/hljs/php';
export {default as plaintext} from 'react-syntax-highlighter/dist/esm/languages/hljs/plaintext';
// export { default as pony } from 'react-syntax-highlighter/dist/esm/languages/hljs/pony';
export {default as powershell} from 'react-syntax-highlighter/dist/esm/languages/hljs/powershell';
export {default as processing} from 'react-syntax-highlighter/dist/esm/languages/hljs/processing';
export {default as profile} from 'react-syntax-highlighter/dist/esm/languages/hljs/profile';
// export { default as prolog } from 'react-syntax-highlighter/dist/esm/languages/hljs/prolog';
export {default as properties} from 'react-syntax-highlighter/dist/esm/languages/hljs/properties';
export {default as protobuf} from 'react-syntax-highlighter/dist/esm/languages/hljs/protobuf';
export {default as puppet} from 'react-syntax-highlighter/dist/esm/languages/hljs/puppet';
export {default as purebasic} from 'react-syntax-highlighter/dist/esm/languages/hljs/purebasic';
export {default as python} from 'react-syntax-highlighter/dist/esm/languages/hljs/python';
export {default as q} from 'react-syntax-highlighter/dist/esm/languages/hljs/q';
export {default as qml} from 'react-syntax-highlighter/dist/esm/languages/hljs/qml';
export {default as r} from 'react-syntax-highlighter/dist/esm/languages/hljs/r';
// export { default as reasonml } from 'react-syntax-highlighter/dist/esm/languages/hljs/reasonml';
// export { default as rib } from 'react-syntax-highlighter/dist/esm/languages/hljs/rib';
export {default as roboconf} from 'react-syntax-highlighter/dist/esm/languages/hljs/roboconf';
// export { default as routeros } from 'react-syntax-highlighter/dist/esm/languages/hljs/routeros';
export {default as rsl} from 'react-syntax-highlighter/dist/esm/languages/hljs/rsl';
export {default as ruby} from 'react-syntax-highlighter/dist/esm/languages/hljs/ruby';
export {default as ruleslanguage} from 'react-syntax-highlighter/dist/esm/languages/hljs/ruleslanguage';
export {default as rust} from 'react-syntax-highlighter/dist/esm/languages/hljs/rust';
export {default as sas} from 'react-syntax-highlighter/dist/esm/languages/hljs/sas';
export {default as scala} from 'react-syntax-highlighter/dist/esm/languages/hljs/scala';
export {default as scheme} from 'react-syntax-highlighter/dist/esm/languages/hljs/scheme';
// export { default as scilab } from 'react-syntax-highlighter/dist/esm/languages/hljs/scilab';
export {default as scss} from 'react-syntax-highlighter/dist/esm/languages/hljs/scss';
export {default as shell} from 'react-syntax-highlighter/dist/esm/languages/hljs/shell';
// export { default as smali } from 'react-syntax-highlighter/dist/esm/languages/hljs/smali';
export {default as smalltalk} from 'react-syntax-highlighter/dist/esm/languages/hljs/smalltalk';
export {default as sml} from 'react-syntax-highlighter/dist/esm/languages/hljs/sml';
// export { default as sqf } from 'react-syntax-highlighter/dist/esm/languages/hljs/sqf';
export {default as sql} from 'react-syntax-highlighter/dist/esm/languages/hljs/sql';
// export { default as stan } from 'react-syntax-highlighter/dist/esm/languages/hljs/stan';
// export { default as stata } from 'react-syntax-highlighter/dist/esm/languages/hljs/stata';
// export { default as step21 } from 'react-syntax-highlighter/dist/esm/languages/hljs/step21';
export {default as stylus} from 'react-syntax-highlighter/dist/esm/languages/hljs/stylus';
// export { default as subunit } from 'react-syntax-highlighter/dist/esm/languages/hljs/subunit';
export {default as swift} from 'react-syntax-highlighter/dist/esm/languages/hljs/swift';
export {default as taggerscript} from 'react-syntax-highlighter/dist/esm/languages/hljs/taggerscript';
// export { default as tap } from 'react-syntax-highlighter/dist/esm/languages/hljs/tap';
export {default as tcl} from 'react-syntax-highlighter/dist/esm/languages/hljs/tcl';
export {default as tex} from 'react-syntax-highlighter/dist/esm/languages/hljs/tex';
export {default as thrift} from 'react-syntax-highlighter/dist/esm/languages/hljs/thrift';
export {default as tp} from 'react-syntax-highlighter/dist/esm/languages/hljs/tp';
export {default as twig} from 'react-syntax-highlighter/dist/esm/languages/hljs/twig';
export {default as typescript} from 'react-syntax-highlighter/dist/esm/languages/hljs/typescript';
export {default as vala} from 'react-syntax-highlighter/dist/esm/languages/hljs/vala';
export {default as vbnet} from 'react-syntax-highlighter/dist/esm/languages/hljs/vbnet';
export {default as vbscriptHtml} from 'react-syntax-highlighter/dist/esm/languages/hljs/vbscript-html';
export {default as vbscript} from 'react-syntax-highlighter/dist/esm/languages/hljs/vbscript';
export {default as verilog} from 'react-syntax-highlighter/dist/esm/languages/hljs/verilog';
// export { default as vhdl } from 'react-syntax-highlighter/dist/esm/languages/hljs/vhdl';
export {default as vim} from 'react-syntax-highlighter/dist/esm/languages/hljs/vim';
// export { default as x86asm } from 'react-syntax-highlighter/dist/esm/languages/hljs/x86asm';
// export { default as xl } from 'react-syntax-highlighter/dist/esm/languages/hljs/xl';
export {default as xml} from 'react-syntax-highlighter/dist/esm/languages/hljs/xml';
export {default as xquery} from 'react-syntax-highlighter/dist/esm/languages/hljs/xquery';
export {default as yaml} from 'react-syntax-highlighter/dist/esm/languages/hljs/yaml';
export {default as zephir} from 'react-syntax-highlighter/dist/esm/languages/hljs/zephir';

export default [
    //'1c',
    // 'abnf',
    'accesslog',
    'actionscript',
    // 'ada',
    'angelscript',
    'apache',
    'applescript',
    // 'arcade',
    'arduino',
    // 'armasm',
    'asciidoc',
    'aspectj',
    // 'autohotkey',
    // 'autoit',
    // 'avrasm',
    'awk',
    // 'axapta',
    'bash',
    'basic',
    // 'bnf',
    // 'brainfuck',
    'cal',
    // 'capnproto',
    // 'ceylon',
    // 'clean',
    'clojure-repl',
    'clojure',
    'cmake',
    'coffeescript',
    // 'coq',
    // 'cos',
    'cpp',
    // 'crmsh',
    'crystal',
    'cs',
    'csp',
    'css',
    'd',
    'dart',
    'delphi',
    'diff',
    'django',
    'dns',
    'dockerfile',
    'dos',
    'dsconfig',
    // 'dts',
    'dust',
    // 'ebnf',
    'elixir',
    'elm',
    'erb',
    'erlang-repl',
    'erlang',
    'excel',
    // 'fix',
    // 'flix',
    // 'fortran',
    'fsharp',
    // 'gams',
    'gauss',
    'gcode',
    'gherkin',
    // 'glsl',
    // 'gml',
    'go',
    'golo',
    'gradle',
    'groovy',
    'haml',
    'handlebars',
    'haskell',
    'haxe',
    'hsp',
    'htmlbars',
    'http',
    'hy',
    // 'inform7',
    'ini',
    // 'irpf90',
    // 'isbl',
    'java',
    'javascript',
    'jboss-cli',
    'json',
    'julia-repl',
    'julia',
    'kotlin',
    // 'lasso',
    'ldif',
    // 'leaf',
    'less',
    'lisp',
    // 'livecodeserver',
    // 'livescript',
    'llvm',
    'lsl',
    'lua',
    'makefile',
    'markdown',
    // 'mathematica',
    // 'matlab',
    // 'maxima',
    'mel',
    // 'mercury',
    // 'mipsasm',
    // 'mizar',
    'mojolicious',
    'monkey',
    'moonscript',
    // 'n1ql',
    'nginx',
    // 'nimrod',
    'nix',
    'nsis',
    'objectivec',
    'ocaml',
    // 'openscad',
    // 'oxygene',
    'parser3',
    'perl',
    'pf',
    'pgsql',
    'php',
    'plaintext',
    // 'pony',
    'powershell',
    'processing',
    'profile',
    // 'prolog',
    'properties',
    'protobuf',
    'puppet',
    'purebasic',
    'python',
    'q',
    'qml',
    'r',
    // 'reasonml',
    // 'rib',
    'roboconf',
    // 'routeros',
    'rsl',
    'ruby',
    'ruleslanguage',
    'rust',
    'sas',
    'scala',
    'scheme',
    // 'scilab',
    'scss',
    'shell',
    // 'smali',
    'smalltalk',
    'sml',
    // 'sqf',
    'sql',
    // 'stan',
    // 'stata',
    // 'step21',
    'stylus',
    // 'subunit',
    'swift',
    'taggerscript',
    // 'tap',
    'tcl',
    'tex',
    'thrift',
    'tp',
    'twig',
    'typescript',
    'vala',
    'vbnet',
    'vbscript-html',
    'vbscript',
    'verilog',
    // 'vhdl',
    'vim',
    // 'x86asm',
    // 'xl',
    'xml',
    'xquery',
    'yaml',
    'zephir'
];