import React, {PureComponent} from "react";
import PropTypes from "prop-types";

// We can not use AsyncLight as it has no support for language autodetection
// which we make use of.
import {Light as SyntaxHighlighter} from 'react-syntax-highlighter';
import supportedLanguages from './supportedLanguages.js';
import {github} from 'react-syntax-highlighter/dist/styles/hljs';
import ErrorBoundary from "../ErrorBoundary";

SyntaxHighlighter.supportedLanguages = supportedLanguages;

class CodeBlock extends PureComponent {
    static propTypes = {
        value: PropTypes.string.isRequired,
        language: PropTypes.string
    };

    static defaultProps = {
        language: undefined
    };

    render() {
        let {language, value} = this.props;

        return (
            <ErrorBoundary fallback={<div>{value}</div>}>
                <SyntaxHighlighter language={language} style={github}>
                    {value}
                </SyntaxHighlighter>
            </ErrorBoundary>
        );
    }
}

export default CodeBlock;