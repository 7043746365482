import React, {useState, useContext, Suspense, lazy} from 'react';
import {
    Input,
    Button,
    CardHeader,
    Card,
    CardBody,
    Alert,
    Label,
    FormGroup,
    Nav,
    InputGroupAddon,
    InputGroup, FormText
} from "reactstrap";
import {useAuth0} from "../../../react-auth0-wrapper";
import ExternalLink from "../../App/components/ExternalLink";
import AppContext from "../../App/AppContext";
import Markdown from "../../App/components/Markdown";
import CodeBlock from "../../App/components/CodeBlock";
import {Switch, Route, Link} from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {NavButton} from "../../ServiceRegistry/components/SectionSelector";

import "swagger-ui-react/swagger-ui.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-solid-svg-icons";

const SwaggerUI = lazy(() => import('swagger-ui-react'));

export default function APIAccess({org}) {
    return <>
        <Card className={"profile-editor mb-3"}>
            <CardHeader className="card-header-tab">
                <div className="card-header-title">
                    <i className="header-icon lnr-store icon-gradient bg-tempting-azure"> </i>
                    <span style={{paddingRight: "5px"}}>Service360 API Access</span>
                </div>
                <div className="btn-actions-pane-right" style={{textTransform: "none"}}>
                    <Nav pills className={"mb-0"}>
                        <NavButton exact to={org.linkToApiAccess()}>Token</NavButton>
                        <NavButton to={org.linkToApiAccess() + "/explorer"}>API Explorer</NavButton>
                    </Nav>
                </div>
            </CardHeader>
            <CardBody>
                <Switch>
                    <Route exact path={org.linkToApiAccess()} component={TokenRetriever}/>
                    <Route path={org.linkToApiAccess() + "/explorer"} component={APIExplorer}/>
                </Switch>
            </CardBody>
        </Card>
    </>
}

function TokenRetriever() {
    const [refreshToken, setRefreshToken] = useState(false);
    const {client: auth0Client} = useAuth0();
    const [copied, setCopied] = useState(false);

    // I'm using private method of an oauth lib.
    // This shit can break with any lib update. Careful, Jimmy!
    const getToken = async () => {
        const token = await auth0Client._getTokenFromIFrame({
            scope: "offline_access",
            ignoreCache: true
        })
        setRefreshToken(token.refresh_token);
    }
    return <>
        {
            refreshToken && <>
                <Alert color={"info"}>
                    <h4 className={"alert-heading"}>Attention!</h4>
                    <p>Store this token securely! You will NOT be able to see it again!</p>
                    <p>You always can create a new token, but it will invalidate the old one.</p>
                </Alert>
                <FormGroup>
                    <Label>Refresh token</Label>
                    <InputGroup>
                        <Input type={"text"} value={refreshToken} disabled={true}/>
                        <InputGroupAddon addonType="append">
                            <CopyToClipboard
                                onCopy={() => {
                                    setCopied(true);
                                    setTimeout(() => {
                                        setCopied(false);
                                    }, 3000)
                                }}
                                text={refreshToken}>
                                <Button color="primary">
                                    <FontAwesomeIcon icon={faCopy}/>
                                </Button>
                            </CopyToClipboard>
                        </InputGroupAddon>
                    </InputGroup>
                    {
                        copied && <FormText color="success">
                            Token has been copied.
                        </FormText>
                    }
                </FormGroup>
                <hr/>
            </>
        }
        <APIAccessHelp/>
        {
            !refreshToken && <>
                <hr/>
                <Button onClick={getToken} color={"primary"}>Get a one-time use refresh token</Button>
            </>
        }
    </>
}

function APIAccessHelp() {
    const {auth0Domain, auth0ClientID, baseURL} = useContext(AppContext);

    return <>
        <p>
            Service360 is an API-first application. Everything you see on the frontend and each operation you
            make in the admin interface
            is available via REST api.
        </p>
        <p>
            Service360 uses <ExternalLink
            to={"https://auth0.com/docs/tokens/refresh-tokens/use-refresh-tokens"}>Auth0 Rotated
            Refresh token</ExternalLink> authentication flow to make sure your data is safe and secure with us.
        </p>
        <p>
            This means that first time you use the refresh token it will be renewed and a new
            token will be returned to you
            (together with the access token) for the future use.
            Make sure you store the returned refresh token otherwise you will not be able to get a new
            one!
        </p>
        <p>
            Another important thing to keep in mind is that refresh token will be expired unless used in 15 days.
        </p>

        <p>
            Sample usage flow can look like:
        </p>
        <ol>
            <li>Retrieve a refresh token from your mutable (!) secrets storage</li>
            <li>
                <b>Exchange</b> your refresh token for an access token and a <b>new</b> refresh token:
                <Markdown
                    renderers={{
                        code: CodeBlock,
                    }}
                    source={
                        "```bash \n" +
                        "curl --request POST \\\n" +
                        "--url 'https://" + auth0Domain + "/oauth/token' \\\n" +
                        "--header 'content-type: application/x-www-form-urlencoded' \\\n" +
                        "--data grant_type=refresh_token \\\n" +
                        "--data 'client_id=" + auth0ClientID + "' \\\n" +
                        "--data refresh_token=<REFRESH_TOKEN_FROM_YOUR_STORAGE>\n" +
                        "```\n"
                    }/>
                In response you will get a bunch of information including a new refresh token and an
                actual access token
            </li>
            <li>
                Store the new refresh token you got from response in your secrets storage
            </li>
            <li>
                Use the access token you got to call Service360 APIs. For example:
                <Markdown
                    renderers={{
                        code: CodeBlock,
                    }}
                    source={
                        "```bash \n" +
                        "curl '" + baseURL + "v1/orgs' \\\n" +
                        "-H 'accept: application/json, text/plain, */*' \\\n" +
                        "-H 'authorization: Bearer <YOUR ACCESS TOKEN>' \\\n" +
                        "--compressed\n" +
                        "```\n"
                    }/>
                Access tokens are valid for one hour. You do NOT need to create a new token for each API
                request!
            </li>
            <li>
                When your access token expires - repeat the steps from the beginning
            </li>
        </ol>
        <p>
            For more details on the Auth0 and OAuth in general please check an <ExternalLink
            to={"https://auth0.com/docs/tokens/refresh-tokens"}>excellent Auth0 documentation</ExternalLink>.
        </p>
    </>
}

function APIExplorer() {
    const {token} = useContext(AppContext);
    const [copied, setCopied] = useState(false);

    const copy = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 3000)
    }

    return <>
        <Alert color={"warning"}>
            <h4 className={"alert-heading"}>Limited lifetime token</h4>
            <p>Access token below has a lifetime of 1 hour and shall be used for testing only. Use a refresh token to
                get permanent access to an API.</p>
            <FormGroup>
                <Label>Testing access token</Label>
                <InputGroup>
                    <Input type={"text"} value={token} disabled={true}/>
                    <InputGroupAddon addonType="append">
                        <CopyToClipboard onCopy={copy} text={token}>
                            <Button color="primary">
                                <FontAwesomeIcon icon={faCopy}/>
                            </Button>
                        </CopyToClipboard>
                    </InputGroupAddon>
                </InputGroup>
                {
                    copied && <FormText color="success">
                        Token has been copied.
                    </FormText>
                }
            </FormGroup>
        </Alert>
        <Suspense fallback={"Loading..."}>
            <SwaggerUI url={"/openapi.yaml"}/>
        </Suspense>
    </>
}