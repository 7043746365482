import './polyfills'
import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import './assets/base.scss';

import {BrowserRouter} from "react-router-dom";
import {Auth0Provider} from "./react-auth0-wrapper";
import config from "./auth_config.json";
import ReactGA from 'react-ga';
import App from "./components/App"
import {ThemeProvider} from "./hooks/useTheme";

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
    window.history.replaceState(
        {},
        document.title,
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
    );
};

Sentry.init({
    dsn: "https://f5386b65cf1e4fc18671048ac5afa729@o470830.ingest.sentry.io/5502031",
    integrations: [
        new Integrations.BrowserTracing(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
});

ReactGA.initialize('UA-156812972-1');

ReactDOM.render(
    <Auth0Provider
        // cacheLocation={"localstorage"}
        // useRefreshTokens={true}
        domain={config.domain}
        client_id={config.clientId}
        audience={config.audience}
        redirect_uri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
    >
        <BrowserRouter>
            <ThemeProvider>
                <App api={config.api} adminURL={config.adminURL} auth0Domain={config.domain} auth0ClientID={config.clientId}/>
            </ThemeProvider>
        </BrowserRouter>
    </Auth0Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
