import React from 'react';
import {Link, NavLink as NavLinkRR, withRouter} from 'react-router-dom';

import {Nav, NavItem, NavLink} from 'reactstrap';
import cx from 'classnames';
import useTheme from "../../../../hooks/useTheme";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboard, faEye, faHeartbeat, faMap, faUsers, faWrench} from "@fortawesome/free-solid-svg-icons";
import usePlugins, {Context} from "../../../../hooks/usePlugins";

function MenuDots({library, className}) {
    const {toggleMobileTopNavi} = useTheme();
    const plugins = usePlugins();

    if (!library) {
        return null;
    }

    return <>
        <div className={cx("header-dots", className)}>
            <Link to={library.linkToRegistry()} onClick={toggleMobileTopNavi}>
                <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                    <div className="icon-wrapper-bg bg-primary"/>
                    <FontAwesomeIcon icon={faClipboard} color="#3f6ad8" fontSize="25px" />
                </div>
            </Link>
            <Link to={library.linkToRiskRadar()} onClick={toggleMobileTopNavi}>
                <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                    <div className="icon-wrapper-bg bg-primary"/>
                    <FontAwesomeIcon icon={faEye} color="#3f6ad8" fontSize="25px" />
                </div>
            </Link>
            <Link to={library.linkToBigPicture()} onClick={toggleMobileTopNavi}>
                <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                    <div className="icon-wrapper-bg bg-primary"/>
                    <FontAwesomeIcon icon={faMap} color="#3f6ad8" fontSize="25px" />
                </div>
            </Link>
            <Link to={library.linkToPulse()} onClick={toggleMobileTopNavi}>
                <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                    <div className="icon-wrapper-bg bg-primary"/>
                    <FontAwesomeIcon icon={faHeartbeat} color="#3f6ad8" fontSize="25px" />
                </div>
            </Link>
            <Link to={library.linkToTech()} onClick={toggleMobileTopNavi}>
                <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                    <div className="icon-wrapper-bg bg-primary"/>
                    <FontAwesomeIcon icon={faWrench} color="#3f6ad8" fontSize="25px" />
                </div>
            </Link>
            <Link to={library.linkToTeam()} onClick={toggleMobileTopNavi}>
                <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                    <div className="icon-wrapper-bg bg-primary"/>
                    <FontAwesomeIcon icon={faUsers} color="#3f6ad8" fontSize="25px" />
                </div>
            </Link>
            {
                plugins.render("TopNavBar:render:Dots", {library: library, toggleMobileTopNavi: toggleMobileTopNavi}, Context(library))
            }
        </div>
    </>
}

function Menu({library}) {
    const plugins = usePlugins();

    if (!library) {
        return null;
    }

    return (
        <Nav className="header-megamenu">
            <NavItem>
                <NavLink tag={NavLinkRR} to={library.linkToRegistry()} activeClassName="active">
                    <FontAwesomeIcon icon={faClipboard} />&nbsp;
                    ServiceRegistry
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={NavLinkRR} to={library.linkToRiskRadar()} className={"nav-link"}>
                    <FontAwesomeIcon icon={faEye} />&nbsp;
                    RiskRadar
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={NavLinkRR} to={library.linkToBigPicture()} className={"nav-link"}>
                    <FontAwesomeIcon icon={faMap} />&nbsp;
                    BigPicture
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={NavLinkRR} to={library.linkToPulse()} className={"nav-link"}>
                    <FontAwesomeIcon icon={faHeartbeat} />&nbsp;
                    Pulse
                </NavLink>
            </NavItem>
            {/*<NavItem>*/}
            {/*    <NavLink disabled={true} tag={NavLinkRR} to={library.linkToADR()} className={"nav-link"}>*/}
            {/*        <Ionicon icon={"ios-book-outline"} />*/}
            {/*        ADR (coming soon)*/}
            {/*    </NavLink>*/}
            {/*</NavItem>*/}
            <NavItem>
                <NavLink tag={NavLinkRR} to={library.linkToTech()} className={"nav-link"}>
                    <FontAwesomeIcon icon={faWrench} />&nbsp;
                    Tech
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={NavLinkRR} to={library.linkToTeam()} className={"nav-link"}>
                    <FontAwesomeIcon icon={faUsers} />&nbsp;
                    Team
                </NavLink>
            </NavItem>
            {
                plugins.render("TopNavBar:render", {library: library}, Context(library))
            }
        </Nav>
    );
}

export default withRouter(Menu);
export const MenuNavDots = withRouter(MenuDots);
